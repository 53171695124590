const project = process.env.REACT_APP_FB_PROJECT;

export const config = {
  projectId: project,
  apiKey: process.env.REACT_APP_FB_KEY,
  authDomain: `${project}.firebaseapp.com`,
  databaseURL: `https://${project}.firebaseio.com`,
  storageBucket: `${project}.appspot.com`,
  messagingSenderId: process.env.REACT_APP_FB_MESSAGING_SENDER
};

export const PaymentApi =
  process.env.REACT_APP_INSTANCE === 'production'
    ? 'https://paymentsv3-nbrjrta2pa-uc.a.run.app'
    : 'https://paymentsv3-rb4bi6rbxa-uc.a.run.app';
// "http://127.0.0.1:5001/arun-techjays/us-central1/payment";

export const ZohoApi =
  process.env.REACT_APP_INSTANCE === 'production'
    ? 'https://zohocrmv3-nbrjrta2pa-uc.a.run.app'
    : 'https://zohocrmv3-rb4bi6rbxa-uc.a.run.app';
// "https://us-central1-silverback-website.cloudfunctions.net/zohocrmV3";
// "http://127.0.0.1:5001/arun-techjays/us-central1/zohocrmV3";
export const AuthorizePaymentV3 =
  process.env.REACT_APP_INSTANCE === 'production'
    ? 'https://authorizepaymentv4-nbrjrta2pa-uc.a.run.app'
    : 'https://authorizepaymentv3-rb4bi6rbxa-uc.a.run.app';
// "http://127.0.0.1:5001/arun-techjays/us-central1/AuthorizePaymentV3";

// export const FUNCTION_API =
//   process.env.NODE_ENV === "production"
//     ? `https://us-central1-${project}.cloudfunctions.net`
//     : `http://localhost:5000/${project}/us-central1`;

export const QUICKBOOKS_API =
  process.env.REACT_APP_INSTANCE === 'production'
    ? 'https://api.intuit.com'
    : 'https://sandbox.api.intuit.com';

export const REFRESH_TOKEN =
  process.env.NODE_ENV === 'production'
    ? '1000.3335161e9fbd225a3ecc34abdf7a56fc.30855c25235400f676676cb3381111e4'
    : '1000.3335161e9fbd225a3ecc34abdf7a56fc.30855c25235400f676676cb3381111e4';

export const REDIRECT_URI =
  process.env.NODE_ENV === 'production'
    ? 'https://silverbackhw-637d2.firebaseapp.com'
    : 'https://silverbackhw-637d2.firebaseapp.com';

export const CLIENT_ID =
  process.env.NODE_ENV === 'production'
    ? '1000.Q6VV4GXV9ZQA11DOWG6NZJ743S0D9J'
    : '1000.Q6VV4GXV9ZQA11DOWG6NZJ743S0D9J';

export const CLIENT_SECRET =
  process.env.NODE_ENV === 'production'
    ? '588a26479c9c61abd2b6ef4ebb8e403781162bd2b4'
    : '588a26479c9c61abd2b6ef4ebb8e403781162bd2b4';
